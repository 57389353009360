<template>
  <a
    href="javascript:;"
    class="p-0 nav-link text-white"
    :class="[NAVBAR_SHOW_NOTIFICATIONS_MENU ? 'show' : '']"
    id="notificationMenuButton"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    @click="NAVBAR_SHOW_NOTIFICATIONS_MENU = NOTIFICATIONS.length <= 0 ? false : !NAVBAR_SHOW_NOTIFICATIONS_MENU"
  >
  <span :class="NOTIFICATIONS.length <= 0 ? 'disabled fa-stack fa-lg' : 'fa-stack fa-lg'" :data-count="NOTIFICATIONS.length">
  <i class="fa fa-bell fa-lg"></i>
</span>
  </a>
  <ul
    class="dropdown-menu dropdown-menu-end me-sm-n4 me-md-n3"
    ref="popupWindow"
    :class="
      NAVBAR_SHOW_NOTIFICATIONS_MENU && NOTIFICATIONS.length ? 'show' : ''
    "
    aria-labelledby="notificationMenuButton"
  >
    <div style="max-height: 500px; overflow-y: auto">
      <li v-for="nth in NOTIFICATIONS" :key="nth">
        <a class="dropdown-item border-radius-md" href="javascript:;">
          <div class="py-1 d-flex">
            <div class="my-auto">
              <div
                class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle"
                v-if="nth.type === 'ASSET'"
              >
                <i class="text-lg opacity-10 ni ni-money-coins"></i>
              </div>
              <div
                class="text-center shadow icon icon-shape bg-gradient-danger rounded-circle"
                v-else-if="nth.type === 'COMPANY'"
              >
                <i class="text-lg opacity-10 ni ni-world"></i>
              </div>
              <div
                class="text-center shadow icon icon-shape bg-gradient-success rounded-circle"
                v-else-if="nth.type === 'COUNTRY'"
              >
                <i class="text-lg opacity-10 ni ni-paper-diploma"></i>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center ms-2">
              <h6 class="mb-1 text-sm font-weight-normal">
                {{ nth.name }}
              </h6>
              <p class="mb-0 text-xs text-secondary">
                <i class="fa fa-edit me-1"></i>
                {{ $t(`Notifications.${nth.type}`) }}
              </p>
            </div>
          </div>
        </a>
      </li>
    </div>
  </ul>
</template>

<script setup>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { NAVBAR_SHOW_NOTIFICATIONS_MENU } from "../../composables/generics";
import { NOTIFICATIONS } from "../../composables/subscription";

const popupWindow = ref(null);

onClickOutside(
  popupWindow,
  (event) => (NAVBAR_SHOW_NOTIFICATIONS_MENU.value = false)
);
</script>
<style>
.fa-stack[data-count]:after{
  position:absolute;
  right:20%;
  top:5%;
  content: attr(data-count);
  font-size:50%;
  padding:.6em;
  border-radius:999px;
  line-height:.75em;
  color: white;
  background:rgba(255,0,0,.85);
  text-align:center;
  min-width:2em;
  font-weight:bold;
}
.disabled{
  cursor: not-allowed; 
  pointer-events: all !important;
}
</style>
