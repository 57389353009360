<template>
  <div class="card p-1 proxima-bg-light z-index-2">
    <div class="text-start proxima-bg-light p-1">
      <h3 class="card-title">Recent Notifications:</h3>
    </div>
    <div class="d-flex justify-content-evenly mb-1">
      <span v-for="(n, index) in PROFILE_TYPES" :key="index" :ref="activeButton" :class="activeButton == n.toString()
          ? 'active-notif-filter-btn badge'
          : 'badge badge-custom'
        " @click="filterNotif(n)" style="width: 19%; cursor: pointer">
        {{ n.toString() }}
      </span>
    </div>
    <NSelect v-model:value="selectedElements" :options="ELEMENT_OPTS" filterable clearable
      placeholder="Select Element" />
    <div v-if="inProgress" class="col-12 mt-1 d-flex justify-content-center">
      <NSpace>
        <NSpin size="medium" class="custom-spin-color" />
      </NSpace>
    </div>
    <div v-else class="overflow-auto" style="height: 350px">
      <div v-if="filteredItems?.length" class="row gx-0">
        <div v-for="nth in filteredItems" :key="nth.id" class="proxima_recent_news__col col-12 p-2"
          style="border-bottom: 1px solid lightgray">
          <div class="d-flex pb-0 row gx-0" style="cursor: pointer">
            <div class="col-2 flex-shrink-1 pt-2" style="width: fit-content"
              @click="goToProfile(nth.commodities[0], nth)">
              <div class="text-center icon icon-shape border-radius-2xl" :class="nth.type === ASSET_PROFILE_TYPE
                  ? 'bg-gradient-primary'
                  : nth.type === COUNTRY_PROFILE_TYPE
                    ? 'bg-gradient-danger'
                    : nth.type === COMPANY_PROFILE_TYPE
                      ? 'bg-gradient-success'
                      : nth.type == SUBSCRIPTION_FILES_PROFILE_TYPE
                        ? 'proxima-blue-bg-gradient'
                        : ''
                ">
                <i class="text-lg opacity-10" :class="nth.type === ASSET_PROFILE_TYPE
                    ? 'ni ni-money-coins'
                    : nth.type === COUNTRY_PROFILE_TYPE
                      ? 'ni ni-paper-diploma'
                      : nth.type === COMPANY_PROFILE_TYPE
                        ? 'ni ni-world'
                        : nth.type === SUBSCRIPTION_FILES_PROFILE_TYPE
                          ? 'ni ni-folder-17'
                          : ''
                  " aria-hidden="true">
                </i>
              </div>
            </div>
            <div class="col-8 flex-grow-1 ms-1 px-1">
              <div class="row" @click="goToProfile(nth.commodities[0], nth)">
                <div class="col-11">
                  <h6 class="mb-0">
                    <span class="notif-card-title">
                      {{ nth.name }}
                    </span>
                  </h6>
                </div>
              </div>
              <p @click="goToProfile(nth.commodities[0], nth)"
                class="flex-grow-1 proxima_recent_news--date mb-0 latest-news-card text-black-50">
                <span>
                  <i class="text-sm ml-1 fa fa-pencil-square-o black-text" aria-hidden="true"></i>
                </span>
                <span class="black-text" @click="goToProfile(nth.commodities[0], nth)">
                  <span v-if="nth.type === SUBSCRIPTION_FILES_PROFILE_TYPE">
                    {{
                      nth.type.charAt(0).toUpperCase().replace("_", " ") +
                      nth.type.slice(1).toLowerCase().replace("_", " ")
                    }}
                  </span>
                  <span v-else>
                    {{
                      nth.type.charAt(0).toUpperCase().replace("_", " ") +
                      nth.type.slice(1).toLowerCase().replace("_", " ")
                    }}
                  </span>
                  updated
                </span>
              </p>
              <p class="flex-grow-1 proxima_recent_news--date mb-0 latest-news-card text-black-50"
                style="width: fit-content">
                <span v-for="(l, index) in nth.commodities" :key="index">
                  <div class="avatar avatar-sm border-radius-md">
                    <span class="text-xs text-bold text-white" @click="goToProfile(l, nth)" data-toggle="tooltip"
                      data-placement="top" :title="el.elements
                          .filter((r) => r.symbol === l)
                          .map((r) => r.name)
                          .toString() ||
                        elements
                          .filter((r) => r.code === l)
                          .map((r) => r.name)
                          .toString() ||
                        l
                        ">
                      <img :src="nth.imageUrl" alt="" />
                    </span>
                  </div>
                </span>
              </p>
            </div>
            <div class="col-1" @click="goToProfile(nth.commodities[0], nth)">
              <i :class="!nth.seen
                  ? 'text-sm fa fa-envelope proxima-secondary'
                  : 'text-sm fa fa-envelope-open-o proxima-grey'
                "></i>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-12 mt-1 d-flex justify-content-center">
        <h5>No new notifications.</h5>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { ROUTES } from "../../routes/names";
import {
  SUBSCRIPTION_INFO,
  NOTIFICATIONS,
  getSubscriptionInfo,
  getNotifications,
  inProgress,
} from "../../composables/subscription";
import { MARKET_SERVICES } from "../../composables/generics";
import { NSelect, NButtonGroup, NButton, NIcon, NSpin, NSpace } from "naive-ui";
import el from "../../utils/elements.json";
import { useRouter } from "vue-router";
import { sortBy } from "lodash";
import {
  openNotification,
  PROFILE_TYPES,
  ASSET_PROFILE_TYPE,
  COMPANY_PROFILE_TYPE,
  COUNTRY_PROFILE_TYPE,
  SUBSCRIPTION_FILES_PROFILE_TYPE,
} from "../../composables/notification";
import { useI18n } from "vue-i18n";
import {
  getUserFiles,
  USER_FILES,
  COST_SERVICES,
  MARKET_SERVICES2,
} from "../../composables/files/list";

const router = useRouter();
const { t } = useI18n();
let selectedType = ref("ALL");
let selectedElements = ref(null);
let notifs = NOTIFICATIONS.value;

const elements = computed(() => {
  const mservices = SUBSCRIPTION_INFO.elements.filter((nth) => {
    return Object.keys(MARKET_SERVICES).includes(nth.code);
  });
  return mservices;
});

const goToProfile = (element, nth) => {
  let ROUTE_NAME;
  if (nth.type === ASSET_PROFILE_TYPE) ROUTE_NAME = ROUTES.ASSET_PROFILE.name;
  if (nth.type === COMPANY_PROFILE_TYPE)
    ROUTE_NAME = ROUTES.COMPANY_PROFILE.name;
  if (nth.type === COUNTRY_PROFILE_TYPE)
    ROUTE_NAME = ROUTES.COUNTRY_PROFILE.name;
  if (element === "Criticals Materials" || element === "Critical Materials")
    element = "CRITICAL_MATERIALS";

  let url = router.resolve({
    name: ROUTE_NAME,
    params: { id: nth.id },
    query: { element },
  });

  if (nth.type === SUBSCRIPTION_FILES_PROFILE_TYPE) {
    url = router.resolve({
      name: ROUTES.FILES.name,
      query: { element: element },
    });
    window.open(url.href, "_blank");
  } else {
    window.open(url.href, "_blank");
  }
  markAsRead(nth);
};

const markAsRead = async (nth) => {
  await openNotification(nth.id);
  return (NOTIFICATIONS.value[NOTIFICATIONS.value.indexOf(nth)].seen = true);
};

const ELEMENT_OPTS = computed(() => {
  let opts = elements.value.map((r) => {
    return {
      value: r.code,
      label: r.name,
      code: r.code,
    };
  });
  return sortBy(opts, "label");
});

let activeButton = ref(null);
const filterNotif = (type) => {
  activeButton.value = type;
  selectedType.value = type;
};

const filteredItems = computed(() => {
  if (selectedType.value === "ALL") {
    notifs = NOTIFICATIONS.value.filter((r) => {
      if (selectedElements.value) {
        return r.commodities.find(
          (element) => element == selectedElements.value
        );
      }
      return NOTIFICATIONS.value;
    });
  }

  if (selectedType.value != "ALL") {
    notifs = NOTIFICATIONS.value.filter((r) => {
      if (selectedElements.value) {
        return (
          r.type === selectedType.value &&
          r.commodities.find((element) => element == selectedElements.value)
        );
      }
      return (
        r.type === selectedType.value ||
        r.commodities.find((element) => element == selectedElements.value)
      );
    });
  }

  if (selectedType.value == "FILES") {
    notifs = NOTIFICATIONS.value.filter((r) => {
      if (selectedElements.value) {
        return (
          r.type === SUBSCRIPTION_FILES_PROFILE_TYPE &&
          r.commodities.find((element) => element == selectedElements.value)
        );
      }
      return (
        r.type === SUBSCRIPTION_FILES_PROFILE_TYPE ||
        r.commodities.find((element) => element == selectedElements.value)
      );
    });
  }
  return notifs;
});

onMounted(async () => {
  if (!USER_FILES.value?.files) await getUserFiles();
  if (!SUBSCRIPTION_INFO.subscriptionPackages) await getSubscriptionInfo();
  if (!NOTIFICATIONS.value) await getNotifications();
});

const getNotificationTitle = (el, elements, l) => {
  const temp =
    el.elements
      .filter((r) => r.symbol === l)
      .map((r) => r.symbol)
      .toString() ||
    elements
      .filter((r) => r.code === l)
      .map((r) => r.code)
      .toString() ||
    l;
  if (temp === "Graphite") return t("MarketServices.elements.graphite");
  if (temp === "SALT") return t("MarketServices.elements.salt");
  if (temp === "Anode" || temp === "Cathode")
    return t("MarketServices.elements.lithium");

  return temp;
};
</script>

<style lang="scss" scoped>
@import url("../../assets/styles/home-page-styles.scss");
</style>
